import { Link, isRouteErrorResponse, useRouteError } from '@remix-run/react';

import { FeedbackDialog } from '~/components/feedback/feedback-dialog.tsx';
import { Button, buttonVariants } from '~/components/ui/button.tsx';

import { Check, Clipboard } from 'lucide-react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { z } from 'zod';

const errorSchema = z.object({
  error: z.object({
    code: z.string().optional(),
    message: z.string(),
    status: z.number(),
    additionalData: z.record(z.string(), z.unknown()),
  }),
});

export const ErrorComponent = ({ fullPage }: { fullPage?: boolean }) => {
  const { t } = useTranslation('common');
  let message = t('errors.invalidRequest');
  let status = 500;
  const response = useRouteError();
  if (isRouteErrorResponse(response)) {
    const error = errorSchema.safeParse(response.data);
    if (error.success) {
      message = error.data.error.message;
      status = error.data.error.status;
    }
  }
  const [copied, setCopied] = React.useState(false);

  return (
    <div className={'w-full h-screen flex justify-center flex-col items-center'}>
      <div className={'p-8 text-center rounded-md border bg-popover max-w-xl w-full space-y-4'}>
        <span className={'space-y-2'}>
          <h2 className={'font-medium text-lg'}>
            {t('error.title')} ({status})
          </h2>
          <p className={'text-sm text-muted-foreground'}>{t('error.description')}</p>
        </span>
        <span className={'flex items-center gap-2 justify-center'}>
          <p
            className={
              'bg-secondary px-5 py-1 rounded border font-medium text-sm font-mono truncate'
            }>
            {message}
          </p>
          <button
            type={'button'}
            className={'p-1 rounded border'}
            onClick={() => {
              navigator.clipboard.writeText(message);
              setCopied(true);
            }}>
            {copied ? <Check className={'size-4'} /> : <Clipboard className={'size-4'} />}
          </button>
        </span>
        <span className={'flex items-center gap-2 justify-center'}>
          <Link className={buttonVariants({ size: 'sm' })} to={'/'}>
            {t('error.options.home')}
          </Link>
          <Button size={'sm'} variant={'secondary'} onClick={() => window.location.reload()}>
            {t('error.options.reload')}
          </Button>
          <FeedbackDialog display={'text'} createdAfterError={true} />
        </span>
      </div>
    </div>
  );
};
