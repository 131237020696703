import {
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '~/components/ui/dialog.tsx';
import {
  DrawerClose,
  DrawerContent,
  DrawerDescription,
  DrawerFooter,
  DrawerHeader,
  DrawerTitle,
  DrawerTrigger,
} from '~/components/ui/drawer.tsx';

import {
  DrawerDialogProvider,
  useDrawerDialogContext,
} from '~/utils/context/dialog-drawer-context.tsx';
import { useMediaQuery } from '~/utils/hooks/use-media-query.ts';

import * as DialogPrimitive from '@radix-ui/react-dialog';
import * as React from 'react';
import { Drawer as DrawerPrimitive } from 'vaul';

export const DrawerDialog = ({
  shouldScaleBackground = true,
  ...props
}: Omit<React.ComponentProps<typeof DrawerPrimitive.Root>, 'fadeFromIndex' | 'snapPoints'>) => {
  const isDesktop = useMediaQuery('(min-width: 768px)');

  const Comp = isDesktop ? DialogPrimitive.Root : DrawerPrimitive.Root;

  return (
    <DrawerDialogProvider isDesktop={isDesktop}>
      <Comp {...props} shouldScaleBackground={shouldScaleBackground}></Comp>
    </DrawerDialogProvider>
  );
};

export const DrawerDialogTrigger = (
  props?: React.ComponentPropsWithoutRef<typeof DrawerPrimitive.Trigger>,
) => {
  const { isDesktop } = useDrawerDialogContext();
  const Comp = isDesktop ? DialogTrigger : DrawerTrigger;
  return <Comp {...props} />;
};

export const DrawerDialogContent = (
  props?: React.ComponentPropsWithoutRef<typeof DrawerPrimitive.Content>,
) => {
  const { isDesktop } = useDrawerDialogContext();
  const Comp = isDesktop ? DialogContent : DrawerContent;
  return <Comp {...props} />;
};

export const DrawerDialogHeader = (props?: React.ComponentPropsWithoutRef<typeof DrawerHeader>) => {
  const { isDesktop } = useDrawerDialogContext();
  const Comp = isDesktop ? DialogHeader : DrawerHeader;
  return <Comp {...props} />;
};

export const DrawerDialogTitle = (props?: React.ComponentPropsWithoutRef<typeof DrawerTitle>) => {
  const { isDesktop } = useDrawerDialogContext();
  const Comp = isDesktop ? DialogTitle : DrawerTitle;
  return <Comp {...props} />;
};

export const DrawerDialogDescription = (
  props?: React.ComponentPropsWithoutRef<typeof DrawerDescription>,
) => {
  const { isDesktop } = useDrawerDialogContext();
  const Comp = isDesktop ? DialogDescription : DrawerDescription;
  return <Comp {...props} />;
};

export const DrawerDialogFooter = (props?: React.ComponentPropsWithoutRef<typeof DrawerFooter>) => {
  const { isDesktop } = useDrawerDialogContext();
  const Comp = isDesktop ? DialogFooter : DrawerFooter;
  return <Comp {...props} />;
};

export const DrawerDialogClose = (props?: React.ComponentPropsWithoutRef<typeof DrawerClose>) => {
  const { isDesktop } = useDrawerDialogContext();
  const Comp = isDesktop ? DialogClose : DrawerClose;
  return <Comp {...props} />;
};
